.custom-toggle-switch {
  margin-left: 8px;

  .mat-slide-toggle-thumb-container {
    width: 12px;
    height: 12px;
    top: 2px;
    left: 2px;
  }

  & .mat-slide-toggle-bar {
    width: 32px;
    height: 16px;
  }

  & .mat-slide-toggle-thumb {
    height: 12px;
    width: 12px;
  }

  &.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #007ac9;
  }
}
