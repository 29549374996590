::-webkit-scrollbar {
  padding-top: 5px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--color-gray10);
  border-radius: 8px;
  padding: 5px 0;
}

::-webkit-scrollbar-thumb {
  margin-top: 5px;
  background: var(--color-gray40);
  border-radius: 8px;
}

.mat-dialog-container {
  background: #fff;
  box-shadow: 0 10px 15px rgb(0 0 0 / 15%);
  border-radius: 10px !important;
}
