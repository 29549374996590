.history-full-dialog {
  & > .mat-dialog-container {
    width: 720px;
    padding: 36px 0;
    position: relative;
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }
}
