.custom-form-field {
  font-family: "Maison Neue", Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #5a5f66;

  .mat-form-field-wrapper {
    padding: unset;
  }

  .mat-form-field-infix {
    width: 155px;
    height: 32px;
    padding: 8px 0 6px;
    border-top: unset;
  }

  .mat-form-field-flex {
    align-items: center;
    max-width: 172px;
  }

  .mat-datepicker-toggle {
    display: block;
    height: 18px;
  }

  .mat-button-ripple.mat-ripple:not(:empty) {
    transform: none !important;
  }

  .mat-button-focus-overlay {
    opacity: 0 !important;
  }

  .mat-form-field-suffix .mat-icon {
    font-size: 22px;
    height: 16px;
    width: 16px;
    line-height: 16px;
  }

  &.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    height: unset;
  }

  &.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 38px 0 0 38px;
    min-width: 38px;
    border-color: #5a5f66;
    background-color: #fff;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 38px 38px 0;
    border-color: #5a5f66;
    background-color: #fff;
  }
}

.mat-select-panel-wrap .mat-select-panel {
  background-color: #f2f8fc;
  border: 1px solid #5a5f66;
  border-radius: 20px;
  margin-top: 38px;
  max-height: 350px;
}

.mat-autocomplete-panel.mat-autocomplete-visible,
.mat-autocomplete-panel-above .mat-autocomplete-panel {
  background-color: #f2f8fc;
  border: 1px solid #5a5f66;
  border-radius: 20px !important;
  margin-top: 8px;
  max-height: 350px;

  & .mat-option {
    font-family: "Maison Neue", Arial, sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #5a5f66;
    height: 33px;
  }
}

.mat-autocomplete-panel-above .mat-autocomplete-panel {
  margin: 0 0 8px;
}
