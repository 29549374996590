.custom-tooltip.mat-tooltip {
  background: #fff;
  color: #4c5055;
  border: 1px solid #adafb3;
  box-shadow: 0 0 35px rgb(0 0 0 / 15%), inset 0 0 30px rgb(0 0 0 / 5%);
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 14px;
  left: -14px;
  position: relative;
}

.custom-tooltip.search-form-header {
  background-color: #fffee2;
  text-align: center;
}

.custom-search-result-item-tooltip.mat-tooltip {
  position: relative;
  left: -15px;
  width: 250px;
  padding: 10px 12px;
  box-shadow: 0 0 35px rgb(0 0 0 / 15%), inset 0 0 30px rgb(0 0 0 / 5%);
  background-color: #fffee2;
  color: #4c5055;
  border: 1px solid #adafb3;
  border-radius: 10px;
  font-size: 12px;
}
