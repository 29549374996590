@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Book.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-BookItalic.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-DemiItalic.woff"), format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Medium.woff"), format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-MediumItalic.woff"), format("woff");
  font-weight: 500;
  font-style: italic;
}
