.skill-level-change-dialog {
  max-width: 912px !important;
  width: 100%;

  & > .mat-dialog-container {
    position: relative;
    padding: 42px 80px 22px;
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }
}
