* {
  font-family: "Maison Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Maison Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  color: #323;
  border-color: gray;
  min-height: 100vh;
  overflow-y: hidden;
}

sm-root {
  width: 100%;
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
