.global-team-list-search-input.mat-form-field {
  display: block;

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-flex {
    border-radius: 100px;
    padding-left: 15px;
    flex-flow: row-reverse;
    background-color: #eeeff0;
    font-size: 14px;
    font-weight: 400;
  }

  .mat-form-field-infix {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
  }

  .mat-input-element {
    position: relative;
    top: -5px;
    margin-left: 6px;
  }

  .mat-form-field-label-wrapper {
    top: -17px;
    left: 6px;
  }

  .mat-form-field-label,
  &.mat-focused .mat-form-field-label {
    color: #5a5f66;
  }

  .mat-form-field-suffix {
    top: 5px;
    font-size: 17px;
  }

  .mat-form-field-underline {
    display: none;
  }
}
