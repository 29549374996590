:root {
  /* Grayscale Colors */
  --color-black: #000;
  --color-gray100: #202020;
  --color-gray80: #4d4d4d;
  --color-gray60: #797979;
  --color-gray40: #a6a6a6;
  --color-gray30: #c7c7c7;
  --color-gray20: #d2d2d2;
  --color-gray10: #e9e9e9;
  --color-gray5: #eeeff0;
  --color-white: #fff;
  --color-neutral-gray50: #adafb3;
  --color-neutral-gray100: #5a5f66;
  /* Primary Colors */
  --color-primary-dark100: #003150;
  --color-primary-dark80: #004979;
  --color-primary-dark60: #0062a1;
  --color-primary100: #007ac9;
  --color-primary80: #089eff;
  --color-primary60: #46b6ff;
  --color-primary40: #83ceff;
  --color-primary20: #c1e7ff;
  --color-primary10: #e0f3ff;
  --color-primary5: #f2f8fc;
  /* Secondary Colors */
  --color-secondary-dark100: #5a092f;
  --color-secondary-dark80: #880e47;
  --color-secondary-dark60: #b5125e;
  --color-secondary100: #e21776;
  --color-secondary80: #ec4191;
  --color-secondary60: #f171ad;
  --color-secondary40: #f5a0c8;
  --color-secondary20: #fad0e4;
  --color-secondary10: #fde7f1;
  /* Tertiary Colors */
  --color-tertiary-dark100: #643400;
  --color-tertiary-dark80: #954e00;
  --color-tertiary-dark60: #c76800;
  --color-tertiary100: #f98200;
  --color-tertiary80: #ff9b2e;
  --color-tertiary60: #ffb462;
  --color-tertiary40: #ffcd97;
  --color-tertiary20: #ffe6cb;
  --color-tertiary10: #fff3e5;
  /* Special Colors */
  --color-positive: #339471;
  --color-positive10: #ebf5f1;
}

* {
  font-family: "Maison Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Maison Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  color: #323;
  border-color: gray;
  min-height: 100vh;
  overflow-y: hidden;
}

sm-root {
  width: 100%;
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

::-webkit-scrollbar {
  padding-top: 5px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--color-gray10);
  border-radius: 8px;
  padding: 5px 0;
}

::-webkit-scrollbar-thumb {
  margin-top: 5px;
  background: var(--color-gray40);
  border-radius: 8px;
}

.mat-dialog-container {
  background: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px !important;
}

@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Book.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-BookItalic.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-DemiItalic.woff"), format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-Medium.woff"), format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("../assets/fonts/maisonNeue/MaisonNeueWEB-MediumItalic.woff"), format("woff");
  font-weight: 500;
  font-style: italic;
}
/* CONTAINS CONTENTFUL RICH TEXT FIXES */
.contentful {
  /* Fix empty lines (double new line in Contentful) */
}
.contentful ul {
  padding-left: 18px;
}
.contentful p {
  margin: 8px 0;
}
.contentful p:empty::before,
.contentful b:empty::before,
.contentful i:empty::before {
  white-space: pre;
  content: " ";
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary100) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--color-primary100) !important;
}

.primary-domain-dialog > .mat-dialog-container,
.secondary-domain-dialog > .mat-dialog-container {
  position: relative;
  width: 912px;
  padding: 55px 55px 60px;
}

.primary-domain-dialog > .mat-dialog-container {
  max-height: 680px;
  width: 860px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}
.primary-domain-dialog > .mat-dialog-container .mat-button-wrapper {
  font-size: 28px;
}

.custom-toggle-switch {
  margin-left: 8px;
}
.custom-toggle-switch .mat-slide-toggle-thumb-container {
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
}
.custom-toggle-switch .mat-slide-toggle-bar {
  width: 32px;
  height: 16px;
}
.custom-toggle-switch .mat-slide-toggle-thumb {
  height: 12px;
  width: 12px;
}
.custom-toggle-switch.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff;
}
.custom-toggle-switch.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #007ac9;
}

.custom-tooltip.mat-tooltip {
  background: #fff;
  color: #4c5055;
  border: 1px solid #adafb3;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.15), inset 0 0 30px rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 14px;
  left: -14px;
  position: relative;
}

.custom-tooltip.search-form-header {
  background-color: #fffee2;
  text-align: center;
}

.custom-search-result-item-tooltip.mat-tooltip {
  position: relative;
  left: -15px;
  width: 250px;
  padding: 10px 12px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.15), inset 0 0 30px rgba(0, 0, 0, 0.05);
  background-color: #fffee2;
  color: #4c5055;
  border: 1px solid #adafb3;
  border-radius: 10px;
  font-size: 12px;
}

.custom-form-field {
  font-family: "Maison Neue", Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #5a5f66;
}
.custom-form-field .mat-form-field-wrapper {
  padding: unset;
}
.custom-form-field .mat-form-field-infix {
  width: 155px;
  height: 32px;
  padding: 8px 0 6px;
  border-top: unset;
}
.custom-form-field .mat-form-field-flex {
  align-items: center;
  max-width: 172px;
}
.custom-form-field .mat-datepicker-toggle {
  display: block;
  height: 18px;
}
.custom-form-field .mat-button-ripple.mat-ripple:not(:empty) {
  transform: none !important;
}
.custom-form-field .mat-button-focus-overlay {
  opacity: 0 !important;
}
.custom-form-field .mat-form-field-suffix .mat-icon {
  font-size: 22px;
  height: 16px;
  width: 16px;
  line-height: 16px;
}
.custom-form-field.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  height: unset;
}
.custom-form-field.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none;
}
.custom-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 38px 0 0 38px;
  min-width: 38px;
  border-color: #5a5f66;
  background-color: #fff;
}
.custom-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 38px 38px 0;
  border-color: #5a5f66;
  background-color: #fff;
}

.mat-select-panel-wrap .mat-select-panel {
  background-color: #f2f8fc;
  border: 1px solid #5a5f66;
  border-radius: 20px;
  margin-top: 38px;
  max-height: 350px;
}

.mat-autocomplete-panel.mat-autocomplete-visible,
.mat-autocomplete-panel-above .mat-autocomplete-panel {
  background-color: #f2f8fc;
  border: 1px solid #5a5f66;
  border-radius: 20px !important;
  margin-top: 8px;
  max-height: 350px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option,
.mat-autocomplete-panel-above .mat-autocomplete-panel .mat-option {
  font-family: "Maison Neue", Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #5a5f66;
  height: 33px;
}

.mat-autocomplete-panel-above .mat-autocomplete-panel {
  margin: 0 0 8px;
}

.level-change-dialog > .mat-dialog-container {
  position: relative;
  max-height: 680px;
  width: 860px;
  padding: 55px 55px 60px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}
.level-change-dialog > .mat-dialog-container .mat-button-wrapper {
  font-size: 28px;
}
.level-change-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.levels-description-dialog > .mat-dialog-container {
  position: relative;
  max-height: 680px;
  width: 860px;
  padding: 55px 55px 60px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}
.levels-description-dialog > .mat-dialog-container .mat-button-wrapper {
  font-size: 28px;
}
.levels-description-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.delete-profile-item-dialog > .mat-dialog-container {
  position: relative;
  max-height: 680px;
  width: 860px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 55px 55px 60px;
}
.delete-profile-item-dialog > .mat-dialog-container .mat-button-wrapper {
  font-size: 28px;
}
.delete-profile-item-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.skill-level-change-dialog {
  max-width: 912px !important;
  width: 100%;
}
.skill-level-change-dialog > .mat-dialog-container {
  position: relative;
  padding: 42px 80px 22px;
}
.skill-level-change-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.history-full-dialog > .mat-dialog-container {
  width: 720px;
  padding: 36px 0;
  position: relative;
}
.history-full-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.global-team-list-search-input.mat-form-field {
  display: block;
}
.global-team-list-search-input.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
.global-team-list-search-input.mat-form-field .mat-form-field-flex {
  border-radius: 100px;
  padding-left: 15px;
  flex-flow: row-reverse;
  background-color: #eeeff0;
  font-size: 14px;
  font-weight: 400;
}
.global-team-list-search-input.mat-form-field .mat-form-field-infix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
}
.global-team-list-search-input.mat-form-field .mat-input-element {
  position: relative;
  top: -5px;
  margin-left: 6px;
}
.global-team-list-search-input.mat-form-field .mat-form-field-label-wrapper {
  top: -17px;
  left: 6px;
}
.global-team-list-search-input.mat-form-field .mat-form-field-label, .global-team-list-search-input.mat-form-field.mat-focused .mat-form-field-label {
  color: #5a5f66;
}
.global-team-list-search-input.mat-form-field .mat-form-field-suffix {
  top: 5px;
  font-size: 17px;
}
.global-team-list-search-input.mat-form-field .mat-form-field-underline {
  display: none;
}

.button .mat-icon.spinner-loading {
  width: 18px;
  height: 18px;
  margin-right: 16px;
  margin-left: -8px;
}
.button .mat-icon.spinner-loading .mat-spinner circle,
.button .mat-icon.spinner-loading .mat-progress-spinner circle {
  stroke: #a6a6a6;
}
.button .mat-icon.spinner-loading .mat-spinner.background circle,
.button .mat-icon.spinner-loading .mat-progress-spinner.background circle {
  stroke: #e9e9e9;
}
.button .mat-icon.spinner-loading .mat-progress-spinner {
  display: inline;
  position: absolute;
}
.button img,
.button .mat-icon {
  margin-right: 8px;
}
.button img.right-icon,
.button .mat-icon.right-icon {
  margin: 0 0 0 8px;
}
.button.icon-button img,
.button.icon-button .mat-icon {
  margin: 0;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: inherit;
}

.mat-sort-header-container {
  align-items: center;
}

.mat-sort-header-arrow {
  color: #fff;
}

.certificate-selection-dialog > .mat-dialog-container {
  position: relative;
  max-height: 490px;
  width: 860px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 55px 55px 60px;
}
.certificate-selection-dialog > .mat-dialog-container .mat-button-wrapper {
  font-size: 28px;
}
.certificate-selection-dialog .mat-button-focus-overlay {
  background-color: transparent;
}

.learning-path-selection-dialog {
  max-width: 847px !important;
  width: 100%;
  height: 625px;
}
.learning-path-selection-dialog > .mat-dialog-container {
  position: relative;
  padding: 66px 0;
}
.learning-path-selection-dialog .mat-button-focus-overlay {
  background-color: transparent;
}