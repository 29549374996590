.levels-description-dialog {
  & > .mat-dialog-container {
    position: relative;
    max-height: 680px;
    width: 860px;
    padding: 55px 55px 60px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 15%);

    .mat-button-wrapper {
      font-size: 28px;
    }
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }
}
