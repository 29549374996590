:root {
  /* Grayscale Colors */
  --color-black: #000;
  --color-gray100: #202020;
  --color-gray80: #4d4d4d;
  --color-gray60: #797979;
  --color-gray40: #a6a6a6;
  --color-gray30: #c7c7c7;
  --color-gray20: #d2d2d2;
  --color-gray10: #e9e9e9;
  --color-gray5: #eeeff0;
  --color-white: #fff;
  --color-neutral-gray50: #adafb3;
  --color-neutral-gray100: #5a5f66;

  /* Primary Colors */
  --color-primary-dark100: #003150;
  --color-primary-dark80: #004979;
  --color-primary-dark60: #0062a1;
  --color-primary100: #007ac9;
  --color-primary80: #089eff;
  --color-primary60: #46b6ff;
  --color-primary40: #83ceff;
  --color-primary20: #c1e7ff;
  --color-primary10: #e0f3ff;
  --color-primary5: #f2f8fc;

  /* Secondary Colors */
  --color-secondary-dark100: #5a092f;
  --color-secondary-dark80: #880e47;
  --color-secondary-dark60: #b5125e;
  --color-secondary100: #e21776;
  --color-secondary80: #ec4191;
  --color-secondary60: #f171ad;
  --color-secondary40: #f5a0c8;
  --color-secondary20: #fad0e4;
  --color-secondary10: #fde7f1;

  /* Tertiary Colors */
  --color-tertiary-dark100: #643400;
  --color-tertiary-dark80: #954e00;
  --color-tertiary-dark60: #c76800;
  --color-tertiary100: #f98200;
  --color-tertiary80: #ff9b2e;
  --color-tertiary60: #ffb462;
  --color-tertiary40: #ffcd97;
  --color-tertiary20: #ffe6cb;
  --color-tertiary10: #fff3e5;

  /* Special Colors */
  --color-positive: #339471;
  --color-positive10: #ebf5f1;
}
