/* CONTAINS CONTENTFUL RICH TEXT FIXES */
.contentful {
  ul {
    padding-left: 18px;
  }

  p {
    margin: 8px 0;
  }

  /* Fix empty lines (double new line in Contentful) */
  p:empty::before,
  b:empty::before,
  i:empty::before {
    white-space: pre;
    content: " ";
  }
}
