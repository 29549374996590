.button {
  .mat-icon.spinner-loading {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    margin-left: -8px;

    .mat-spinner circle,
    .mat-progress-spinner circle {
      stroke: #a6a6a6;
    }

    .mat-spinner.background circle,
    .mat-progress-spinner.background circle {
      stroke: #e9e9e9;
    }

    .mat-progress-spinner {
      display: inline;
      position: absolute;
    }
  }

  img,
  .mat-icon {
    margin-right: 8px;

    &.right-icon {
      margin: 0 0 0 8px;
    }
  }

  &.icon-button {
    img,
    .mat-icon {
      margin: 0;
    }
  }
}
