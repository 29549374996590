.learning-path-selection-dialog {
  max-width: 847px !important;
  width: 100%;
  height: 625px;

  & > .mat-dialog-container {
    position: relative;
    padding: 66px 0;
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }
}
