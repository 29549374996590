.primary-domain-dialog,
.secondary-domain-dialog {
  & > .mat-dialog-container {
    position: relative;
    width: 912px;
    padding: 55px 55px 60px;
  }
}

.primary-domain-dialog {
  & > .mat-dialog-container {
    max-height: 680px;
    width: 860px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 15%);

    .mat-button-wrapper {
      font-size: 28px;
    }
  }
}
